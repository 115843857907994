import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import {
  DashboardCategory,
  LogicalBot,
  RpaDashboard,
  UserPreferences,
  UnassignedBot,
  EffectiveDate,
  SignOffActions,
  EffectiveDatesResponse,
  ScheduledReport,
  SignOffHistoryItem,
  EditBotRunner,
} from '@/types/om27.types';
import {
  getRpaOpsDashboard,
  getUnassignedBots,
  getSignOffEffectiveDates,
  getRpaOpsSignOff,
  getRpaOpsSignOffHistory,
  editBotRunner,
  getRpaGetBots,
  getBotListForLastRun
} from '@/api/om27.api';
import {
  getScheduledReportList,
  createNewScheduledReport,
  editScheduledReport,
  deleteScheduledReport,
} from '@/api/scheduled-reports.api';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { formatDate } from '@/utils/datetime';
import { SimpleAutoSignOffBot } from '../types/edit-bot.types';

@Module({ name: 'om27', store, dynamic: true, namespaced: true })
class Mod extends VuexModule {
  logicalBots: LogicalBot[] = [];
  unassignedBots: UnassignedBot[] = [];
  scheduledReports: ScheduledReport[] = [];
  userPreferences: UserPreferences = {
    preferred_timezone: '',
    timezone_short: '',
  };
  categories: DashboardCategory[] = [];
  signOffHistory: SignOffHistoryItem[] | null = null;

  searchText = '';
  showActionNeededOnly = false;
  editLogicalBotHistoryData: {
    linkedBots: SimpleAutoSignOffBot[] | null,
  } = {
    linkedBots: null,
  };
  editBotRunner:EditBotRunner | null = null
  @Mutation
  public setSignOffHistory(data: SignOffHistoryItem[]) {
    this.signOffHistory = data;
  }

  @Mutation
  public setSearchText(searchText: string) {
    this.searchText = searchText;
  }

  @Mutation
  public setShowActionNeededOnly(value: boolean) {
    this.showActionNeededOnly = !!value;
  }

  @Mutation
  public setEditHistoryLogicalBotLinkedBots(data: SimpleAutoSignOffBot[]) {
    this.editLogicalBotHistoryData.linkedBots = data;
  }

  @Mutation
  private setData(data: RpaDashboard): void {
    this.logicalBots = data.logicalBots;
    this.userPreferences.preferred_timezone = data.userPreferences.preferred_timezone;
    data.categories.forEach(category =>
      category.subcategories.forEach(subcategory => {
        if (subcategory.subsubcategories.length > 1) {
          const ids = subcategory.subsubcategories.flatMap(ssc => ssc.logicalBotIds);
          subcategory.subsubcategories.unshift({
            name: 'All',
            logicalBotIds: uniq(ids),
          });
          subcategory.subsubcategories = uniqBy(subcategory.subsubcategories, 'name');
        }
      }),
    );
    this.categories = data.categories;
  }

  @Mutation
  private setUnassignedBots(data: UnassignedBot[]) {
    this.unassignedBots = Array.isArray(data) ? data : [];
  }

  @Mutation
  private setScheduledReportList(data: ScheduledReport[]) {
    this.scheduledReports = Array.isArray(data) ? data : [];
  }

  @Mutation
  private setUserPreferences(data: Partial<UserPreferences>) {
    this.userPreferences = {
      ...this.userPreferences,
      ...data,
    };
  }

 
  @Action({ rawError: true })
  public async getDashboard() {
    const { data } = await getRpaOpsDashboard();
    this.setData(data);
    return data;
  }

  @Action({ rawError: true })
  public async getUnassignedBots() {
    const { data } = await getUnassignedBots();
    this.setUnassignedBots(data as UnassignedBot[]);
    return data;
  }

  @Action({ rawError: true })
  public async getScheduledReportList(): Promise<ScheduledReport[]> {
    const { data } = await getScheduledReportList();
    this.setScheduledReportList(data);
    return data;
  }

  @Action({ rawError: true })
  public async getScheduledReportByName(reportName: string): Promise<ScheduledReport | null> {
    const { scheduledReports } = this;
    if (!scheduledReports || !scheduledReports.length) {
      await this.getScheduledReportList();
    }
    const report = scheduledReports.find(
      (report) => (report.reportName === reportName)
    );
    return report || null;
  }

  @Action({ rawError: true })
  public async createScheduledReport(payload: ScheduledReport) {
    const { data } = await createNewScheduledReport(payload);
    return data;
  }

  @Action({ rawError: true })
  public async editScheduledReport(payload: ScheduledReport) {
    const { data } = await editScheduledReport(payload);
    return data;
  }

  @Action({ rawError: true })
  public async deleteScheduledReport(reportName: string) {
    await deleteScheduledReport(reportName);
    await this.getScheduledReportList();
  }

  @Action({ rawError: true })
  public async getSignOffEffectiveDates(signoffId: number) {
    const { data }: { data: EffectiveDatesResponse } = await getSignOffEffectiveDates(signoffId);
    const timezone = data.IANATimeZone.citycode;
    const { shortzone } = data.IANATimeZone;

    this.setUserPreferences({
      preferred_timezone: timezone,
      timezone_short: shortzone,
    });

    const effectiveDates: EffectiveDate[] = Object.values(SignOffActions).map((key) => {
        const { error, tickets, effectivedates } = data[key];
        return {
          key,
          error,
          dates: effectivedates.map(({ type, effectivedate: date }: { type: string, effectivedate: string }) => ({
            type,
            value: date,
            formatted: `${formatDate(date, timezone, {
              timeOnly: false,
              dateOnly: false,
              format: 'MMM DD YYYY; HH:mm A zzz',
              timezoneAbbrv: shortzone,
            })} (${type})`,
          })),
          tickets,
        };
    });

    return effectiveDates;
  }

  @Action({ rawError: true })
  public async getSignOffData(signOffId: number) {
    const { data } = await getRpaOpsSignOff(signOffId);
    this.setUserPreferences({
      preferred_timezone: data.userPreferences.preferredTimeZone,
      timezone_short: data.userPreferences.IANATimeZone?.shortzone,
    });
    return data;
  }

  @Action({ rawError: true })
  public async getSignOffHistoryData(logicalBotId: number) {
    const { data } = await getRpaOpsSignOffHistory(logicalBotId);
    const sortedByDate = data.sort((a, b) => b.signofffor > a.signofffor ? 1 : -1);
    this.setSignOffHistory(sortedByDate);
    return sortedByDate;
  }
  @Action({ rawError: true })
  public async editRunner(payload: EditBotRunner) {
    const { data } = await editBotRunner(payload);
    return data;
  }
 
  @Action({ rawError: true })
  public async getRpaBots() {
    const { data } = await getRpaGetBots();
    return data;
  }

  @Action({ rawError: true })
  public async getBotListForLast() {
    const { data } = await getBotListForLastRun();
    return data;
  }
  
}

export const RpaOpsModule = getModule(Mod);
