import { CustomCell, LogData } from '@/types/om27.types';
import dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import advancedFormatPlugin from 'dayjs/plugin/advancedFormat';
import { randId } from '@/utils/components';
import { SignOffColumnData } from '@/types/signoff.types';
import { CreateElement } from 'vue';
import GskPopover from '@/components/gsk-components/popover/GskPopover.vue';
import NewlineText from '@/components/NewlineText.vue';
import { TZ_ABBREVATIONS } from '@/constants';
import { padLeadingZero } from '@/utils/number';

// vue + tsx typing is broken
const GPopover = GskPopover as any;
const NT = NewlineText as any;

dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);
dayjs.extend(advancedFormatPlugin);

export interface DateRenderOptions {
  timeOnly: boolean;
  dateOnly: boolean;
  format?: string;
  timezoneAbbrv?: string;
}

export const defaultDateRenderOptions = {
  timeOnly: false,
  dateOnly: false,
} as const;

function getOpts<T>(
  row: T,
  field: keyof T,
  timeZone: string,
  infoField: keyof T | '',
  options: DateRenderOptions = defaultDateRenderOptions,
) {
  const d = row[field];
  const date = formatDate(d ? d + '' : '', timeZone, options);
  const info = infoField ? row[infoField] : '';
  const f = field.toString().toLowerCase();
  const isError = f.includes('error');
  const isConfirmed = f.includes('confirmed');
  const isSuccess = f.includes('trusted');
  return {
    date,
    isError,
    isConfirmed,
    isSuccess,
    info,
  };
}

export function getDateRenderer<T, V>(
  field: keyof T,
  timeZone: string,
  infoField: keyof T | '' = '',
  options: DateRenderOptions = defaultDateRenderOptions,
): CustomCell<T, V> {
  return ({ row }, h) => {
    return dateRender(getOpts(row, field, timeZone, infoField, options), h);
  };
}

export function getLogDateRenderer(
  field: keyof LogData,
  timeZone: string,
  infoField: keyof LogData | '' = '',
  options: DateRenderOptions = defaultDateRenderOptions,
): CustomCell<LogData, SignOffColumnData> {
  return ({ row }, h) => {
    const date = formatDate(row[field], timeZone, options);
    const info = infoField ? row[infoField] : '';
    const isNn = field === 'nonNullDateField';
    const f = field.toString().toLowerCase();
    const isError = (isNn && !!row.errorDate) || f.includes('error');
    const isConfirmed = (isNn && !!row.confirmedStartDate) || f.includes('confirmed');
    const isSuccess = (isNn && !!row.trustedFinishedMsg) || f.includes('trusted');
    const spacer = isNn && !(isError || isConfirmed || isSuccess);
    return dateRender(
      {
        date,
        isError,
        isConfirmed,
        isSuccess,
        info,
        spacer,
      },
      h,
    );
  };
}

function renderIcon(data: RenderData, h: CreateElement) {
  const { isError, isConfirmed, isSuccess, spacer } = data;
  if (isError) {
    return <gsk-icon class="status-overdue">warning_circle</gsk-icon>;
  }

  if (isConfirmed) {
    return <gsk-icon class="status-confirmed">checkmark_outline</gsk-icon>;
  }

  if (isSuccess) {
    return <gsk-icon class="status-success">checkmark_filled</gsk-icon>;
  }

  if (spacer) {
    return <gsk-icon style="visibility: hidden; pointer-events: none;">checkmark_filled</gsk-icon>;
  }
}

interface RenderData {
  date: string;
  isError: boolean;
  isConfirmed: boolean;
  isSuccess: boolean;
  info: unknown;
  spacer?: boolean;
}
function dateRender(data: RenderData, h: CreateElement) {
  const { date, info } = data;
  const id = randId();
  return (
    <div class="status">
      <span id={id}>
        {date && renderIcon(data, h)}
        <span>{date || '—'}</span>
      </span>
      {info && typeof info === 'string' && (
        <GPopover anchorId={id} hover pinnable copy>
          <NT style="font-size: 12px">{info}</NT>
        </GPopover>
      )}
    </div>
  );
}

const formatter = (dt: dayjs.Dayjs, formatString: string) => {
  const zoneLongName = dt.offsetName('long') || '';
  const formatted = dt.format(formatString);
  if (!zoneLongName) {
    return formatted;
  }
  return formatted.replace(zoneLongName, TZ_ABBREVATIONS[zoneLongName]);
};

export function formatDate(
  date: string | number,
  timeZone: string,
  options: DateRenderOptions = defaultDateRenderOptions,
): string {
  if (!date) {
    return '';
  }

  let dt = dayjs(date + '');
  if (timeZone) {
    dt = dt.tz(timeZone);
  }
  let formatString;

  if (options.format) {
    formatString = options.format;
  } else if (options.timeOnly) {
    formatString = 'h:mm A';
  } else if (options.dateOnly) {
    formatString = 'MM/DD/YYYY, zzz';
  } else {
    formatString = 'MM/DD/YYYY, h:mm A zzz';
  }

  return formatter(dt, formatString);
}

export function getCurrentTzOffsetString() {
  return dayjs().format('z');
}

export function calculateTimeForCurrentOffset(
  hours: number | string,
  minutes: number | string,
) {
  const date = new Date();
  date.setUTCHours(Number(hours));
  date.setUTCMinutes(Number(minutes));
  const hr = padLeadingZero(date.getHours(), 2);
  const min = padLeadingZero(date.getMinutes(), 2);
  return `${hr}:${min}`;
}

export function calculateHoursAndMinutesForUTC(
  hours: number | string,
  minutes: number | string,
) {
  const date = new Date();
  date.setHours(Number(hours));
  date.setMinutes(Number(minutes));
  return {
    hours: date.getUTCHours(),
    minutes: date.getUTCMinutes(),
  };
}